<template>
  <v-container id="users" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-container class="py-0">
          <form novalidate>
            <md-card-content class="custom-card-cont">
              <v-card class="card-cont">
                <v-row class="user-search-bar">    
                  <v-col cols="6" xl="6" lg="6" class="supplier-align">
                      New Suppliers   
                  </v-col>
                  <v-col cols="6" xl="6" lg="6" class="text-field-cont">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      style="color:#473068"
                      class="custom-text-field mb-1"
                      single-line
                      hide-details 
                    >
                    </v-text-field> 
                    <v-btn style="background-color:#442D65;" @click="searchfunc">Search</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </md-card-content>
          </form>
          <v-row class="header-container">
            <v-col cols="2" class="text-element-1">Company</v-col>
            <v-col cols="2" class="text-element-2">Email</v-col>
            <v-col cols="3" class="text-element-2">Name</v-col>
            <v-col cols="3">Joined on</v-col>
            <v-col cols="2" class="text-element-5">Approval</v-col>
          </v-row>
          <div v-if="suppliers.length">
            <v-card class="card-cont" v-for="(supplier, index) in suppliers"
                  :key="`supplier${index}`"
                  >
                <v-row class="user-container">
                  <v-col cols="2" class="text-element"> <span>{{ supplier.brand_name }}</span></v-col>
                  <v-col cols="2" class="text-element"><span> {{ supplier.email }}</span></v-col>
                  <v-col cols="3" class="text-element"> <span>{{ supplier.full_name }}</span></v-col>
                  <v-col cols="3">
                    <span>
                      {{ new Date(supplier.created_at).toLocaleString() }}
                    </span>
                  </v-col>
                  <v-col cols="2" class="text-element-btn">
                    <v-btn @click="approveSupplier(supplier.id)" color="#70D0CE"
                    >
                    <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </v-col>
                </v-row> 
            </v-card>
          </div>
          <v-card v-else class="card-cont">
              No Suppliers Available
          </v-card>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <Pagination
            style="margin-right: -30px;"
            :current-page="currentPage"
            :total-count="totalCount"
            @load-page="loadPage"
            v-if="suppliers.length"
          />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Pagination from '../../components/Pagination';
import notification from "../../notification";
import restAdapter from "../../restAdapter";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      currentPage:1,
      totalCount:0,
      selected: [],
      search: "",
      loading: false,
      headers: [
        { text: "Company", value: "brand_name" },
        { text: "Email", value: "email" },
        { text: "Name", value: "full_name" },
        { text: "Joined on", value: "created_at", filterable: false },
        // { text: "Roles", value: "roles" },
        { text: "Approval", value: "approve" },
      ],
      suppliers: [],
      selectedHeader:"",
    };
  },

  async mounted() {
    await this.fetchSuppliers();
  },
  methods: {
    async fetchSuppliers() {
      this.loading = true;
      const response = await restAdapter.get("/api/view_unapproved_suppliers?page=" + this.currentPage);
      if (response.data){
        this.suppliers = response.data.data;
        this.totalCount = response.data.total;
      } else {
        this.selected = [];
        this.suppliers = [];
      }
      this.loading = false;
    },

    approveSupplier(id) {
      restAdapter.post("/api/approve_supplier/" + id).then((res) => {
        notification.success("Supplier(s) Approved");
        this.fetchSuppliers();
      });
    },
    searchfunc(){
      if(!this.search){
        this.fetchSuppliers();
      }
      else{
        this.suppliers = this.suppliers.filter(supplier => supplier.full_name.includes(this.search))
      }

    },
    loadPage(page) {
      this.currentPage = page;
      this.fetchSuppliers();
    },
  },
};
</script>

<style>
.text-element-5{
  text-align: right;
  padding-right: 25px;
}
.style-paginate{
  border-radius: 3px;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-right: -20px;
}
.supplier-align{
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #473068;
}
.text-field-cont{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.user-search-bar{
  background: #FFFFFF;
  border-radius: 7px;
}
.btn-cont{
  text-align: right;
}
.card-cont{
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
}
.header-container{
  color: #442D65;
  font-weight: 600;
  font-size: 16px;
  margin: -10px 0px -30px 0px;
  text-align: left;
}
.user-container{
  align-items: center;
  color: #442D65;
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  background: #FFFFFF;
  text-align: left;
}
.custom-text-field ::v-deep label {
  color: #442D65;
}
.custom-card-cont{
  margin: 0px -15px;
}
.text-element-btn{
  text-align: right;
}
.text-element-1{
  padding-left: 0px;
}
.text-element-2{
  padding-left: 4px;
}
.theme--light.v-input {
  margin-right: 20px;
}
.theme--light.v-label {
  color: #442D65 !important;  
}
.mdi-magnify::before {
  color: #442D65 !important;
}
.mdi-magnify::after {
  color: #442D65 !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #442D65 !important;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: #442D65;
  border-color: #442D65;
  border-style: solid;
  border-width: thin 0 thin 0;
}
</style>